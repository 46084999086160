@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  

.masonaryDiv > div{
  margin-left: auto;
  margin-right: auto;
}

.bidVal {
  white-space: pre-line;
}